import ApplicationController from './application_controller'
import { find } from 'lodash'

export default class extends ApplicationController {
  static targets = [ 'button', 'form' ]

  connect() {
    document.querySelector('.only--calls').classList.toggle('hidden', this.data.get('what') !== 'calls')
    document.querySelector('.only--meetings').classList.toggle('hidden', this.data.get('what') !== 'meetings')
  }

  toggle(e) {
    e.preventDefault()

    const target = e.target

    const actionURL = this.formTarget.action.split('/')
    actionURL.pop()

    if (target.parentNode.id.includes('call')) {
      this.data.set('what', 'calls')
    } else if (target.parentNode.id.includes('meeting')) {
      this.data.set('what', 'meetings')
    } else {
      this.data.set('what', 'messages')
    }

    this.buttonTargets.forEach((buttonTarget) => {
      buttonTarget.classList.remove('outreach-what__item--active')
    })

    find(this.buttonTargets, (buttonTarget) => buttonTarget.id.includes(this.data.get('what'))).classList.add('outreach-what__item--active')

    actionURL.push(this.data.get('what'))
    this.formTarget.action = actionURL.join('/')

    document.querySelector('.only--calls').classList.toggle('hidden', this.data.get('what') !== 'calls')
    document.querySelector('.only--meetings').classList.toggle('hidden', this.data.get('what') !== 'meetings')
  }
}
